<template>
  <div class="fluid-container api">
    <p class="text-center">
      <!-- CLI version: 3.{{ cliVer }} :: API version: {{ ver }} -->
      <small>Copyright &copy; {{ currentYear }} | All rights reserved.</small>
    </p>
  </div>
</template>
<script>
import moment from "moment";
moment.locale("it");

export default {
  methods: {},
  props: {
    version: String,
  },
  computed: {
    currentYear() {
      return moment().year();
    },
    // ver() {
    //   return this.version;
    // },
    // bu() {
    //   return `http://${window.location.hostname.split(".")[0]}.${
    //     process.env.VUE_APP_BASE_URL
    //   }`;
    // },
    // cliVer() {
    //   return process.env.VUE_APP_VERSION || "0";
    // },
  },
};
</script>
<style lang="scss" scoped>
/* Fluid container API */
p {
  font-size: 1rem;
  line-height: 1.5rem;
}
.fluid-container.api {
  background: #6f6259;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.fluid-container.api > *:last-child {
  margin-bottom: 0px;
  color: #fff;
  font-size: 1rem;
}
</style>
